import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const AboutPage = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: {
            eq: "stock-photo-sun-panels-and-blue-sky-149513144.jpg"
          }
        ) {
          childImageSharp {
            gatsbyImageData(
              width: 300
              quality: 50
              webpOptions: { quality: 70 }
              placeholder: BLURRED
            )
          }
        }
      }
    `
  );
  const image = getImage(placeholderImage);

  return (
    <Layout>
      <div className="flex flex-col w-full">
        <div className="flex flex-row">
          <h1>Τι είναι η Virtual Sun;</h1>
        </div>
        <div className="flex flex-row w-full">
          <div className="flex flex-col w-1/3 mr-5 mt-5">
            <GatsbyImage
              image={image}
              className="border-vs-yellow border rounded-md h-full"
            />
          </div>
          <div className="flex flex-col w-2/3">
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const Head = () => <Seo title="About Virtual Sun" />;

export default AboutPage;
